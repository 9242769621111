import React from "react"
import "./header.css"

function Header() {
  return (
    <header
      id="header">
    </header>
  )
}

export default Header
