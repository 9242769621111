import React from "react"
import "./headline.css"

export function Headline1() {
  return <h1 className="headline1">Previous Projects</h1>
}
export function Headline2() {
  return <h1 className="headline2">Tech Skills</h1>
}

// export default Headline1
